import React from "react";
import { Helmet } from "react-helmet";
import Home from "../components/HomeComp";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Home - Joshi Dairy Products</title>
        <meta
          name="description"
          content="Welcome to Joshi Dairy Products, your source for fresh dairy products."
        />
      </Helmet>
      <Home />
    </>
  );
};

export default HomePage;
