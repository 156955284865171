import React from "react";
import Navbar from "../components/NavbarComp";

const NavbarPage = () => {
  return (
    <div>
      <Navbar />
    </div>
  );
};

export default NavbarPage;
