import React from "react";
import { Helmet } from "react-helmet";
import Infrastructure from "../components/InfrastructureComp";

const InfrastructurePage = () => {
  return (
    <>
      <Helmet>
        <title>Infrastructure - Joshi Dairy Products</title>
        <meta
          name="description"
          content="Discover the infrastructure of Joshi Dairy Products."
        />
        <link
          rel="canonical"
          href="https://www.joshidairyproducts.com/infrastructure"
        />
      </Helmet>
      <Infrastructure />
    </>
  );
};

export default InfrastructurePage;
