import React from "react";
import { Helmet } from "react-helmet";
import Gallery from "../components/GalleryComp";

const GalleryPage = () => {
  return (
    <>
      <Helmet>
        <title>Gallery - Joshi Dairy Products</title>
        <meta
          name="description"
          content="View our gallery of dairy products."
        />
        <link
          rel="canonical"
          href="https://www.joshidairyproducts.com/gallery"
        />
      </Helmet>
      <Gallery />
    </>
  );
};

export default GalleryPage;
