import React from "react";
import { Helmet } from "react-helmet";
import Product from "../components/ProductComp";

const ProductsPage = () => {
  return (
    <>
      <Helmet>
        <title>Our Products - Joshi Dairy Products</title>
        <meta
          name="description"
          content="Explore our range of dairy products."
        />
        <link
          rel="canonical"
          href="https://www.joshidairyproducts.com/products"
        />
      </Helmet>
      <Product />
    </>
  );
};

export default ProductsPage;
