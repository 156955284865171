import React from "react";
import { Helmet } from "react-helmet";
import About from "../components/AboutUs";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Joshi Dairy Products</title>
        <meta
          name="description"
          content="Learn about Joshi Dairy Products and our journey in the dairy industry."
        />
        <link
          rel="canonical"
          href="https://www.joshidairyproducts.com/about"
        />
      </Helmet>
      <About />
    </>
  );
};

export default AboutPage;
