import React from "react";
import { Helmet } from "react-helmet";
import Contact from "../components/ContactUs";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us - Joshi Dairy Products</title>
        <meta
          name="description"
          content="Get in touch with Joshi Dairy Products."
        />
        <link
          rel="canonical"
          href="https://www.joshidairyproducts.com/contact"
        />
      </Helmet>
      <Contact />
    </>
  );
};

export default ContactPage;
